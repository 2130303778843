import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import axios from "axios";
import "../../css/ProductPage.css";
import { useAuth } from "../../context/auth";
import { toast } from "react-hot-toast";
import { HeartFilled } from "@ant-design/icons";
import Loader from "../../components/Loader";

const ProductPage = () => {
  const [subCategory, setSubCategory] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [products, setProducts] = useState([]);
  const { category, subcategory } = useParams();
  const [wishlist, setWishlist] = useState([]);
  const [auth] = useAuth();
  const [priceRange, setPriceRange] = useState("");
  const [loading, setLoading] = useState(true);
  const handlePriceRangeChange = (event) => {
    setPriceRange(event.target.value);
  };
  const apiUrl = `${process.env.REACT_APP_API}`;
  useEffect(() => {
    axios
      .get(`${apiUrl}/categories/${category}/subcategories`)
      .then((response) => {
        setSubCategory(response.data.subCategories);
      })
      .catch((error) => {
        console.error(error);
      });
    setSelectedSubCategory(subcategory);
  }, [subcategory]);

  useEffect(() => {
    if (selectedSubCategory) {
      axios
        .get(
          `${apiUrl}/categories/${category}/subcategory/${selectedSubCategory}/products`
        )
        .then((response) => {
          const filteredProducts = filterProductsByPrice(response.data);
          const filteredProductsQ = filterProductByQuantity(filteredProducts);
          setProducts(filteredProductsQ);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false); // Set loading to false after API call is complete
        });
    }
  }, [selectedSubCategory, priceRange]);

  const filterProductByQuantity = (products) => {
    return products.filter((p) => {
      return p.productSize.sizes.some((size) => size.quantity > 0);
    });
  };

  const filterProductsByPrice = (products) => {
    if (priceRange === "LessThan50") {
      return products.filter((p) =>
        p.salePrice > 0 ? p.salePrice < 50 : p.productPrice < 50
      );
    } else if (priceRange === "50to100") {
      return products.filter((p) =>
        p.salePrice > 0
          ? p.salePrice >= 50 && p.salePrice <= 100
          : p.productPrice >= 50 && p.productPrice <= 100
      );
    } else if (priceRange === "MoreThan100") {
      return products.filter((p) =>
        p.salePrice > 0 ? p.salePrice > 100 : p.productPrice > 100
      );
    } else {
      return products;
    }
  };

  const fetchWishlist = async () => {
    try {
      const userId = auth.currentUser.id;
      if (userId) {
        const response = await axios.get(`${apiUrl}/wishlist`);
        setWishlist(response.data.products);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchWishlist();
  }, [auth.currentUser.id]);
  const isInWishlist = (productId) => {
    return wishlist.some((item) => item._id === productId);
  };

  const addToWishlist = async (productId) => {
    try {
      fetchWishlist();
      const response = await axios.post(`${apiUrl}/wishlist/add`, {
        productId,
      });
      setWishlist([...wishlist, response.data.products]);
      toast.success("Added to Wishlist");
      await fetchWishlist();
    } catch (error) {
      console.error(error);
    }
  };

  const getDays = (product) => {
    const productDate = product.createdAt.substring(0, 10);
    const createdDate = new Date(productDate);
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1 and pad with '0' if necessary
    const day = String(currentDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    const todayDate = new Date(formattedDate);
    const totalDays = todayDate - createdDate;
    const daysDifference = Math.floor(totalDays / (1000 * 60 * 60 * 24));
    return daysDifference;
  };

  const removeFromWishlist = async (productId) => {
    try {
      if (!auth) {
        return;
      }
      await axios.delete(`${apiUrl}/wishlist/delete/${productId}`);
      const updatedWishlist = wishlist.filter(
        (products) => products._id !== productId
      );
      setWishlist(updatedWishlist);
      toast.success("Removed from Wishlist");
      await fetchWishlist();
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <>
      <Header />
      {loading && <Loader />}
      <div className="container mt-5 box">
        <div className="row mb-3">
          <div className="col-5 col-md-2 col-lg-2 ">
            <select
              className="form-select dropdown"
              id="productType"
              value={selectedSubCategory}
              onChange={(event) => setSelectedSubCategory(event.target.value)}
            >
              {subCategory.map((data) => (
                <option key={data.name} value={data.slug}>
                  {data.name}
                </option>
              ))}
            </select>
          </div>

          <div
            className="col-6 col-md-2 col-lg-2"
            style={{ marginLeft: "25px" }}
          >
            <select
              className="form-select"
              aria-label="Filter by Price"
              value={priceRange}
              onChange={handlePriceRangeChange}
            >
              <option value="">All Prices</option>
              <option value="LessThan50">Less than $50</option>
              <option value="50to100">$50 - $100</option>
              <option value="MoreThan100">$100 and more</option>
            </select>
          </div>
        </div>

        {/* <hr /> */}
        <div className="product-list row  row-cols-1 row-cols-md-4 g-4">
          {products.length > 0 &&
            products.map((product) => (
              <div className="product col-6 col-md-3" key={product.id}>
                <div className="card h-100">
                  <div className="position-relative">
                    <Link
                      to={`/products/${product._id}`}
                      className="text-decoration-none"
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: "instant" });
                      }}
                    >
                      <img
                        src={product.images.front}
                        alt={product.name}
                        className="card-img-top img-fluid image-container"
                        style={{
                          Width: "100%",
                          objectFit: "cover",
                          objectPosition: "center center",
                        }}
                        loading="lazy"
                      />
                      <span>
                        {getDays(product) <= 90 ? (
                          <img
                            className="new"
                            src="https://subcategories-image-upload-aws-1.s3.amazonaws.com/banner_354+Background+Removed.png"
                          />
                        ) : null}
                      </span>
                      {product.salePrice > 0 && (
                        <span className="off">
                          {Math.round(
                            ((product.productPrice - product.salePrice) /
                              product.productPrice) *
                              100
                          )}
                          % off
                        </span>
                      )}
                    </Link>
                    <button
                      className={`position-absolute bottom-0 end-0 btn ${
                        isInWishlist(product._id)
                          ? "heart-buttonSelected"
                          : "heart-buttonUnselected"
                      }`}
                      onClick={() => {
                        if (!localStorage.getItem("auth")) {
                          toast.error("You're not logged in");
                          return;
                        }
                        if (isInWishlist(product._id)) {
                          removeFromWishlist(product._id);
                        } else {
                          addToWishlist(product._id);
                        }
                      }}
                    >
                      <HeartFilled
                        style={{
                          fontSize: "1.4rem",
                          borderRadius: "50px",
                          backgroundColor: "#002e6a",
                          padding: "7px",
                        }}
                      />
                    </button>
                  </div>

                  <div className="card-body d-flex flex-column justify-content-center align-items-center">
                    <Link
                      to={`/products/${product._id}`}
                      className="text-decoration-none"
                    >
                      <h4 className="card-title card-title-design mb-3 pb-0">
                        {product.productName}
                      </h4>
                    </Link>
                    <div class="divider" />
                    <div className="d-flex justify-content-between align-items-center w-100 pt-3">
                      <div>
                        {product.salePrice > 0 ? (
                          <p className="card-text priceTag flex-grow-0">
                            <span className="salePrice">
                              ${product.salePrice}
                            </span>
                            <span className="oldPrice">
                              ${product.productPrice}
                            </span>
                          </p>
                        ) : (
                          <p className="card-text priceTag flex-grow-0">
                            ${product.productPrice}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          {products.length === 0 && (
            <div className="d-flex justify-content-center">
              <h3>Exciting new products are on their way !</h3>
            </div>
          )}
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default ProductPage;
