import React, { useEffect, useState } from "react";
import "../../css/ProductAddPage.css";
import AdminHeader from "../../components/header/AdminHeader";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import axios from "axios";

function ProductUpdatePage() {
  const navigate = useNavigate();
  const [productName, setProductName] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [salePrice, setSalePrice] = useState("");
  const [productSize, setProductSize] = useState({
    sizes: [{ label: "", quantity: "" }],
  });
  const [productDescription, setProductDescription] = useState("");
  const [frontImageFile, setFrontImageFile] = useState(null);
  const [rightImageFile, setRightImageFile] = useState(null);
  const [leftImageFile, setLeftImageFile] = useState(null);
  const [backImageFile, setBackImageFile] = useState(null);
  const [frontImage, setFrontImage] = useState("");
  const [rightImage, setRightImage] = useState("");
  const [leftImage, setLeftImage] = useState("");
  const [backImage, setBackImage] = useState("");
  const [featured, setFeatured] = useState("");
  const [productId, setProductId] = useState("");

  const [formErrors, setFormErrors] = useState({});

  const params = useParams();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  const onFileChange1 = (e) => {
    const file = e.target.files[0];
    console.log(file);
    if (file) {
      setFrontImageFile(file);
      console.log(frontImageFile);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setFrontImageFile(file);
        setFrontImage(reader.result);
      };
    } else {
      setFrontImageFile(null);
      setFrontImage("");
    }
  };
  const onFileChange2 = (e) => {
    const file = e.target.files[0];
    if (file) {
      setLeftImageFile(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setLeftImageFile(file);
        setLeftImage(reader.result);
      };
    } else {
      setLeftImageFile(null);
      setLeftImage("");
    }
  };

  const onFileChange3 = (e) => {
    const file = e.target.files[0];
    if (file) {
      setRightImageFile(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setRightImageFile(file);
        setRightImage(reader.result);
      };
    } else {
      setRightImageFile(null);
      setRightImage("");
    }
  };

  const onFileChange4 = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBackImageFile(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setBackImageFile(file);
        setBackImage(reader.result);
      };
    } else {
      setBackImageFile(null);
      setBackImage("");
    }
  };

  const onSizeChange = (event, index) => {
    const { name, value } = event.target;
    const sizes = [...productSize.sizes];
    sizes[index][name] = value;
    setProductSize({
      ...productSize,
      sizes,
    });
  };

  const handleAddSize = () => {
    setProductSize({
      ...productSize,
      sizes: [...productSize.sizes, { label: "", quantity: "" }],
    });
  };

  const handleRemoveSize = () => {
    const newSizes = [...productSize.sizes];
    newSizes.pop();
    setProductSize({ ...productSize, sizes: newSizes });
  };

  useEffect(() => {
    getProduct();
  }, []);

  useEffect(() => {
    getProducts();
  }, []);

  const apiUrl = `${process.env.REACT_APP_API}`;
  const getProduct = async () => {
    try {
      const res = await fetch(`${apiUrl}/product/${params.productId}`, {
        method: "GET",
      });
      const data = await res.json();
      setProductName(data.productName);
      setProductDescription(data.productDescription);
      setProductSize(data.productSize);
      setFeatured(data.isFeatured);
      setProductPrice(data.productPrice);
      setSalePrice(data.salePrice);
      setProductId(data._id);
      // Extract the filename from the image URL
      const filename1 = data.images.front;

      setFrontImage(filename1);

      const filename2 = data.images.back;

      setBackImage(filename2);

      const filename3 = data.images.left;
      setLeftImage(filename3);

      const filename4 = data.images.right;
      setRightImage(filename4);
    } catch (err) {
      console.log(err);
    }
  };

  const getProducts = async () => {
    try {
      const res = await fetch(`${apiUrl}/products`, {
        method: "GET",
      });
      const data = await res.json();
    } catch (err) {
      console.log("error");
    }
  };

  const validateForm = () => {
    let errors = {};

    if (!productName.trim()) {
      errors.productName = "Name is required";
    } else if (productName.length < 3 || productName.length > 50) {
      errors.productName = "Name must be between 3 and 50 characters";
    }

    if (!productPrice) {
      errors.productPrice = "Price is required";
    } else if (!/^\d+(\.\d{1,2})?$/.test(productPrice)) {
      errors.productPrice = "Price must be a valid number";
    } else if (isNaN(productPrice) || productPrice <= 0) {
      errors.productPrice = "Please enter a valid price";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const updateProduct = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const formData = new FormData();
      formData.append("productName", productName);
      formData.append("productPrice", productPrice);
      if (salePrice) {
        formData.append("salePrice", salePrice);
      }
      formData.append("productSize", JSON.stringify(productSize));
      if (productDescription) {
        formData.append("productDescription", productDescription);
      }
      if (featured) {
        formData.append("isFeatured", featured);
      }
      if (frontImageFile) {
        formData.append("front", frontImageFile);
      }
      if (leftImageFile) {
        formData.append("left", leftImageFile);
      }
      if (rightImageFile) {
        formData.append("right", rightImageFile);
      }
      if (backImageFile) {
        formData.append("back", backImageFile);
      }

      try {
        const res2 = await axios.put(
          `${apiUrl}/product/${productId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        toast.success("Product Updated Successfully!");
        navigate("/admin/product/view");
      } catch (error) {
        console.log(error);
        toast.error("Error updating product.");
      }
    }
  };

  const improveDescription = () => {
    // Make an API request to your backend to improve the product description
    axios
      .post(`${apiUrl}/improve-description`, {
        productDescription: productDescription,
      })
      .then((response) => {
        // Update the state with the improved description
        setProductDescription(response.data.improvedDescription);
        console.log(response.data.improvedDescription);
      })
      .catch((error) => {
        console.error("Error improving description:", error);
      });
  };


  return (
    <>
      <AdminHeader />
      <div className="container pt-5">
        <div className="mt-5 pt-3">
          <div className="card shadow-lg p-3">
            <h1 className="text-center"> Edit Product</h1>
            <form onSubmit={updateProduct} encType="multipart/form-data">
              <div className="row mb-3">
                <label
                  htmlFor="productName"
                  className="col-sm-4 col-form-label"
                >
                  Name of the Product
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    id="productName"
                    value={productName}
                    onChange={(event) => setProductName(event.target.value)}
                  />
                  {formErrors.productName && (
                    <span className="text-danger">
                      {formErrors.productName}
                    </span>
                  )}
                </div>
              </div>
              <div className="row mb-3">
                <label
                  htmlFor="productPrice"
                  className="col-sm-4 col-form-label"
                >
                  Price
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    id="productPrice"
                    value={productPrice}
                    onChange={(event) => setProductPrice(event.target.value)}
                  />
                  {formErrors.productPrice && (
                    <span className="text-danger">
                      {formErrors.productPrice}
                    </span>
                  )}
                </div>
              </div>
              <div className="row mb-3">
                <label htmlFor="salePrice" className="col-sm-4 col-form-label">
                  Sale-Price
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    id="salePrice"
                    value={salePrice}
                    onChange={(event) => setSalePrice(event.target.value)}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <label
                  htmlFor="productSize"
                  className="col-sm-4 col-form-label"
                >
                  Product Sizes
                </label>
                <div className="col-sm-8 d-flex flex-wrap">
                  {productSize.sizes.map((size, index) => (
                    <div
                      key={index}
                      className="me-2 mb-2"
                      style={{ flexBasis: "50%" }}
                    >
                      <div className="row">
                        <div className="col">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={`Label`}
                            name="label"
                            value={size.label}
                            onChange={(e) => onSizeChange(e, index)}
                          />
                        </div>
                        <div className="col">
                          <input
                            type="number"
                            className="form-control"
                            placeholder={`Qty`}
                            name="quantity"
                            value={size.quantity}
                            onChange={(e) => onSizeChange(e, index)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="col-sm-8">
                    <button
                      type="button"
                      className="btn btn-outline-secondary"
                      onClick={handleAddSize}
                    >
                      Add Size
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-secondary ms-3"
                      onClick={handleRemoveSize}
                    >
                      Remove Size
                    </button>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <label
                  htmlFor="productDescription"
                  className="col-sm-4 col-form-label"
                >
                  Description
                </label>
                <div className="col-sm-8">
                  <textarea
                    className="form-control"
                    id="productDescription"
                    rows="5"
                    value={productDescription}
                    onChange={(event) =>
                      setProductDescription(event.target.value)
                    }
                    style={{ minHeight: "170px" }}
                  ></textarea>
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-sm-4 col-form-label"></label>
                <div className="col-sm-8">
                  <button
                    type="button"
                    className="btn button"
                    onClick={improveDescription}
                  >
                    Make it better
                  </button>
                </div>
              </div>
              <div className="row mb-3">
                <label htmlFor="featured" className="col-sm-4 col-form-label">
                  Featured
                </label>
                <div className="col-sm-8">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="featured"
                      id="featuredYes"
                      value="yes"
                      checked={featured === true}
                      onChange={(event) => setFeatured(event.target.value)}
                    />
                    <label className="form-check-label" htmlFor="featuredYes">
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="featured"
                      id="featuredNo"
                      value="no"
                      checked={featured === false}
                      onChange={(event) => setFeatured(event.target.value)}
                    />
                    <label className="form-check-label" htmlFor="featuredNo">
                      No
                    </label>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row mb-3">
                <label className="col-sm-4 col-form-label">
                  Current Front Image
                </label>
                <img
                  src={frontImage}
                  className="img-"
                  alt="imageUrl"
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                    objectPosition: "center center",
                    marginBottom: "5px",
                    marginLeft: "10px",
                  }}
                />
              </div>
              <div className="row mb-3">
                <label className="col-sm-4 col-form-label">
                  Add New Front Image
                </label>
                <div className="col-sm-8">
                  {!frontImageFile && !frontImage && <p>No image chosen</p>}
                  <input type="file" id="image" onChange={onFileChange1} />
                </div>
              </div>
              <hr />
              <div className="row mb-3">
                <label className="col-sm-4 col-form-label">
                  Current Left Image
                </label>
                <img
                  src={leftImage}
                  className="img-"
                  alt="imageUrl"
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                    objectPosition: "center center",
                    marginBottom: "5px",
                    marginLeft: "10px",
                  }}
                />
              </div>
              <div className="row mb-3">
                <label className="col-sm-4 col-form-label">
                  Add New Left Image
                </label>
                <div className="col-sm-8">
                  {!leftImageFile && !leftImage && <p>No image chosen</p>}
                  <input type="file" id="image" onChange={onFileChange2} />
                </div>
              </div>
              <hr />
              <div className="row mb-3">
                <label className="col-sm-4 col-form-label">
                  Current Right Image
                </label>
                <img
                  src={rightImage}
                  className="img-"
                  alt="imageUrl"
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                    objectPosition: "center center",
                    marginBottom: "5px",
                    marginLeft: "10px",
                  }}
                />
              </div>
              <div className="row mb-3">
                <label className="col-sm-4 col-form-label">
                  Add New Right Image
                </label>
                <div className="col-sm-8">
                  {!rightImageFile && !rightImage && <p>No image chosen</p>}
                  <input type="file" id="image" onChange={onFileChange3} />
                </div>
              </div>{" "}
              <hr />
              <div className="row mb-3">
                <label className="col-sm-4 col-form-label">
                  Current Back Image
                </label>
                <img
                  src={backImage}
                  className="img-"
                  alt="imageUrl"
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                    objectPosition: "center center",
                    marginBottom: "5px",
                    marginLeft: "10px",
                  }}
                />
              </div>
              <div className="row mb-3">
                <label className="col-sm-4 col-form-label">
                  {" "}
                  Add New Back Image
                </label>
                <div className="col-sm-8">
                  {!backImageFile && !backImage && <p>No image chosen</p>}
                  <input type="file" id="image" onChange={onFileChange4} />
                </div>
              </div>
              <div className="row mb-3">
                <label
                  htmlFor="submitButton"
                  className="col-sm-4 col-form-label"
                ></label>
                <hr />
                <div className="col-sm-8">
                  <button type="submit" className="btn button mt-3 col-6">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductUpdatePage;
