import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "../../css/ProductDetailPage.css";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { useCart } from "../../context/cart";
import axios from "axios";
import { useAuth } from "../../context/auth";
import { toast } from "react-hot-toast";
import { HeartFilled } from "@ant-design/icons";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import PopUp from "../../components/PopUp";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";

const ProductDetailPage = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const { addToCart } = useCart();
  const [selectedSize, setSelectedSize] = useState("");
  const [wishlist, setWishlist] = useState([]);
  const [auth] = useAuth();
  const [isHoveredFront, setIsHoveredFront] = useState(false);
  const [isHoveredBack, setIsHoveredBack] = useState(false);
  const [isHoveredLeft, setIsHoveredLeft] = useState(false);
  const [isHoveredRight, setIsHoveredRight] = useState(false);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [related, setRelated] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [btnName, setBtnName] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  //NEWFUNCTIONS

  const [openModal, setOpenModal] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const sizechart =
    "https://subcategories-image-upload-aws-1.s3.amazonaws.com/Size+Chart.png";

  const openFullImage = (image) => {
    setModalImage(image);
    setOpenModal(true);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    // Initial check on component mount
    handleResize();

    // Attach event listener for resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // This function calculates the position of the cursor relative to the image
  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.clientX - left) / width) * 100;
    const y = ((e.clientY - top) / height) * 100;
    setCursorPosition({ x, y });
  };
  const handleSizeSelection = (size) => {
    setSelectedSize(size);
  };
  const handleAddToCart = (product, size) => {
    if (!selectedSize) {
      toast.error("Please select a size");
      return;
    }
    const price =
      product.salePrice > 0 ? product.salePrice : product.productPrice;

    const chosenSize = product.productSize.sizes.find(
      (sizeObj) => sizeObj.label === size
    );

    const availableQty = chosenSize.quantity;

    addToCart(product, size, 1, price, availableQty);
    // toast.success(product.productName + " added to cart");
  };

  const filterProductByQuantity = (products) => {
    return products.filter((p) => {
      return p.productSize.sizes.some((size) => size.quantity > 0);
    });
  };
  const apiUrl = `${process.env.REACT_APP_API}`;
  const loadRelated = async (productId, categoryId) => {
    try {
      const { data } = await axios.get(
        `${apiUrl}/related-products/${productId}/${categoryId}`
      );

      setRelated(filterProductByQuantity(data));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/product/${id}`);
        const data = await response.json();
        setProduct(data);
        await loadRelated(data._id, data.subCategory);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [id, selectedSize]);


  useEffect(() => {
    const fetchWishlist = async () => {
      try {
        const response = await axios.get(`${apiUrl}/wishlist`);
        setWishlist(response.data.products);
      } catch (error) {
        console.error(error);
      }
    };
  
    if (auth.currentUser && auth.currentUser.id) {
      fetchWishlist();
    }
  }, [auth.currentUser]);

  
  const isInWishlist = (productId) => {
    return wishlist.some((item) => item._id === productId);
  };

  if (!product) {
    return <Loader />;
  }
  const addToWishlist = async (productId) => {
    try {
      const response = await axios.post(`${apiUrl}/wishlist/add`, {
        productId,
      });
      setWishlist([...wishlist, response.data.products]);
      toast.success("Added to wishlist");
    } catch (error) {
      console.error(error);
    }
  };
  const removeFromWishlist = async (productId) => {
    try {
      const auth = JSON.parse(localStorage.getItem("auth"));
      if (!auth) {
        return;
      }
      await axios.delete(`${apiUrl}/wishlist/delete/${productId}`);
      const updatedWishlist = wishlist.filter(
        (products) => products._id !== productId
      );
      setWishlist(updatedWishlist);
      toast.success("Removed from Wishlist");
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <>
      <Header></Header>
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-6">
            <div className="row allImages rowKa">
              {!isMobile ? (
                <>
                  {" "}
                  <div className="col-md-6">
                    <div
                      className="image-wrapper"
                      style={{ height: "430px", overflow: "hidden" }}
                    >
                      <img
                        src={product.images.front}
                        alt={product.productName}
                        className="img-fluid dis-img"
                        onMouseEnter={() => setIsHoveredFront(true)}
                        onMouseLeave={() => setIsHoveredFront(false)}
                        onMouseMove={handleMouseMove}
                        style={{
                          Width: "100%",
                          objectFit: "cover",
                          objectPosition: "center center",
                          transition: "transform 0.5s",
                          transform: isHoveredFront ? "scale(3.0)" : "scale(1)",
                          transformOrigin: `${cursorPosition.x}% ${cursorPosition.y}%`,
                          paddingBottom: "17px",
                        }}
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="image-wrapper"
                      style={{ height: "430px", overflow: "hidden" }}
                    >
                      <img
                        src={product.images.left}
                        alt={product.productName}
                        className="img-fluid dis-img"
                        onMouseEnter={() => setIsHoveredLeft(true)}
                        onMouseLeave={() => setIsHoveredLeft(false)}
                        onMouseMove={handleMouseMove}
                        style={{
                          Width: "100%",
                          objectFit: "cover",
                          objectPosition: "center center",
                          transition: "transform 0.5s",
                          transform: isHoveredLeft ? "scale(3.0)" : "scale(1)",
                          transformOrigin: `${cursorPosition.x}% ${cursorPosition.y}%`,
                          paddingBottom: "17px",
                        }}
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="image-wrapper"
                      style={{ height: "430px", overflow: "hidden" }}
                    >
                      <img
                        src={product.images.right}
                        alt={product.productName}
                        className="img-fluid dis-img"
                        onMouseEnter={() => setIsHoveredRight(true)}
                        onMouseLeave={() => setIsHoveredRight(false)}
                        onMouseMove={handleMouseMove}
                        style={{
                          Width: "100%",
                          objectFit: "cover",
                          objectPosition: "center center",
                          transition: "transform 0.5s",
                          transform: isHoveredRight ? "scale(3.0)" : "scale(1)",
                          transformOrigin: `${cursorPosition.x}% ${cursorPosition.y}%`,
                        }}
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="image-wrapper"
                      style={{ height: "430px", overflow: "hidden" }}
                    >
                      <img
                        src={product.images.back}
                        alt={product.productName}
                        className="img-fluid dis-img"
                        onMouseEnter={() => setIsHoveredBack(true)}
                        onMouseLeave={() => setIsHoveredBack(false)}
                        onMouseMove={handleMouseMove}
                        style={{
                          Width: "100%",
                          objectFit: "cover",
                          objectPosition: "center center",
                          transition: "transform 0.5s",
                          transform: isHoveredBack ? "scale(3.0)" : "scale(1)",
                          transformOrigin: `${cursorPosition.x}% ${cursorPosition.y}%`,
                        }}
                        loading="lazy"
                      />
                    </div>
                  </div>{" "}
                </>
              ) : (
                <>
                  <div className="col-md-6">
                    <div
                      className="image-wrapper"
                      style={{ height: "430px", overflow: "hidden" }}
                    >
                      <img
                        src={product.images.front}
                        alt={product.productName}
                        className="img-fluid dis-img"
                        onClick={() => openFullImage(product.images.front)}
                        style={{
                          Width: "100%",
                          objectFit: "cover",
                          objectPosition: "center center",
                          paddingBottom: "17px",
                        }}
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="image-wrapper"
                      style={{ height: "430px", overflow: "hidden" }}
                    >
                      <img
                        src={product.images.left}
                        alt={product.productName}
                        className="img-fluid dis-img"
                        onClick={() => openFullImage(product.images.left)}
                        style={{
                          Width: "100%",
                          objectFit: "cover",
                          objectPosition: "center center",

                          paddingBottom: "17px",
                        }}
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="image-wrapper"
                      style={{ height: "430px", overflow: "hidden" }}
                    >
                      <img
                        src={product.images.right}
                        alt={product.productName}
                        className="img-fluid dis-img"
                        onClick={() => openFullImage(product.images.right)}
                        style={{
                          Width: "100%",
                          objectFit: "cover",
                          objectPosition: "center center",
                        }}
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="image-wrapper"
                      style={{ height: "430px", overflow: "hidden" }}
                    >
                      <img
                        src={product.images.back}
                        alt={product.productName}
                        className="img-fluid dis-img"
                        onClick={() => openFullImage(product.images.back)}
                        style={{
                          Width: "100%",
                          objectFit: "cover",
                          objectPosition: "center center",
                        }}
                        loading="lazy"
                      />
                    </div>
                  </div>{" "}
                </>
              )}
            </div>
          </div>
          <div className="col-md-6 allName">
            <h1 className="pName"> {product.productName}</h1>

            <hr />
            <p className="productPrice">
              {product.salePrice > 0 ? (
                <p className="card-text priceTag flex-grow-0">
                  <span className="salePrice">${product.salePrice}</span>
                  {product.salePrice > 0 && (
                    <span
                      style={{
                        backgroundColor: "#f44336",
                        color: "#fff",
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "6px 8px",
                        borderRadius: "0 0 0 8px",
                        marginRight: "5px",
                      }}
                    >
                      {Math.round(
                        ((product.productPrice - product.salePrice) /
                          product.productPrice) *
                          100
                      )}
                      % off
                    </span>
                  )}
                  <span className="oldPrice">${product.productPrice}</span>
                </p>
              ) : (
                <p className="card-text priceTag flex-grow-0">
                  ${product.productPrice}
                </p>
              )}
            </p>
            {/* Used for Sale */}
            {/* <h6
              className="d-flex justify-content-start"
              style={{ color: "#C70044", fontWeight: "450" }}
            >
              Extra 15% discount for End of Year Sale at Checkout !
            </h6> */}
            <p style={{ fontWeight: "450" }}>Select size: </p>
            {product.productSize.sizes.length === 0 && <h3>Free Size</h3>}
            {product.productSize.sizes.length > 0 &&
              product.productSize.sizes.map(
                (size) =>
                  size.quantity > 0 && (
                    <button
                      key={size._id}
                      className={`btn size-button ${
                        selectedSize === size.label ? "size-button-active" : ""
                      }`}
                      onClick={() => handleSizeSelection(size.label)}
                      style={{ border: "solid 1px" }}
                    >
                      {size.label}
                    </button>
                  )
              )}
            {product.subCategory === "64524e6af31946bee9690f78" ? (
              <p style={{ fontWeight: "350" }}>
                <a
                  href="#"
                  onClick={() => openFullImage(sizechart)}
                  style={{
                    Width: "100%",
                    objectFit: "cover",
                    objectPosition: "center center",
                  }}
                >
                  {" "}
                  <i>Size-Chart</i>
                </a>
              </p>
            ) : null}

            <div className="row mt-4">
              <div className="col-12">
                <div className="d-flex justify-content-start">
                  <button
                    className="btn button m-2 add-to-cart"
                    onClick={() => {
                      if (!localStorage.getItem("auth")) {
                        setShowPopUp(true);
                        setBtnName("cart");
                        return;
                      } else {
                        handleAddToCart(product, selectedSize);
                      }
                    }}
                  >
                    <i className="fa-solid fa-cart-shopping"></i> Add to Cart
                  </button>

                  <button
                    className="btn button m-2"
                    onClick={() => {
                      if (!localStorage.getItem("auth")) {
                        setShowPopUp(true);
                        setBtnName("wishlist");
                        return;
                      }
                      if (isInWishlist(product._id)) {
                        removeFromWishlist(product._id);
                      } else {
                        addToWishlist(product._id);
                      }
                    }}
                  >
                    <i
                      className={`fa-regular fa-heart ${
                        isInWishlist(product._id)
                          ? "heart-buttonSelected"
                          : "heart-buttonUnselected"
                      }`}
                    ></i>{" "}
                    Wishlist
                  </button>
                  {showPopUp && (
                    <PopUp
                      loginButtonVisible={true}
                      onLoginClick={() => {
                        navigate("/login", {
                          state: { from: location.pathname },
                        });
                      }}
                      onCloseClick={() => {
                        setShowPopUp(false);
                      }}
                    >
                      Please Login to add products to {btnName}.
                    </PopUp>
                  )}
                </div>
              </div>
            </div>
            <hr />
            <p>Product Description:</p>
            <p style={{ fontWeight: "350" }}>{product.productDescription}</p>
          </div>
        </div>
        <hr style={{ paddingBottom: "15px" }} />
        <h3 style={{ paddingBottom: "15px" }}>
          {" "}
          {related.length === 0 ? "" : "Related Products"}
        </h3>

        <div className="product-list row row-cols-1 row-cols-md-4 g-4">
          {related.length > 0 &&
            related.map((product) => (
              <div className="product col-6 col-md-3" key={product.id}>
                <div className="card h-100">
                  <div className="position-relative">
                    <Link
                      to={`/products/${product._id}`}
                      className="text-decoration-none"
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: "instant" });
                      }}
                    >
                      <img
                        src={product.images.front}
                        alt={product.name}
                        className="card-img-top img-fluid image-container"
                        // style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                        style={{
                          Width: "100%",
                          objectFit: "cover",
                          objectPosition: "center center",
                        }}
                      />
                      {product.salePrice > 0 && (
                        <span className="off">
                          {Math.round(
                            ((product.productPrice - product.salePrice) /
                              product.productPrice) *
                              100
                          )}
                          % off
                        </span>
                      )}
                    </Link>
                    <button
                      className={`position-absolute bottom-0 end-0 btn ${
                        isInWishlist(product._id)
                          ? "heart-buttonSelected"
                          : "heart-buttonUnselected"
                      }`}
                      onClick={() => {
                        if (!localStorage.getItem("auth")) {
                          toast.error("You're not logged in");
                          return;
                        }
                        if (isInWishlist(product._id)) {
                          removeFromWishlist(product._id);
                        } else {
                          addToWishlist(product._id);
                        }
                      }}
                    >
                      <HeartFilled
                        style={{
                          fontSize: "1.4rem",
                          borderRadius: "50px",
                          backgroundColor: "#002e6a",
                          padding: "7px",
                        }}
                      />
                    </button>
                  </div>

                  <div className="card-body d-flex flex-column align-items-start">
                    <Link
                      to={`/products/${product._id}`}
                      className="text-decoration-none"
                    >
                      <h4 className="card-title card-title-design">
                        {product.productName}
                      </h4>
                    </Link>
                    <div class="divider" />
                    <div className="d-flex justify-content-between align-items-center w-100 pt-3">
                      <div>
                        {product.salePrice > 0 ? (
                          <p className="card-text priceTag flex-grow-0">
                            <span className="salePrice">
                              ${product.salePrice}
                            </span>
                            <span className="oldPrice">
                              ${product.productPrice}
                            </span>
                          </p>
                        ) : (
                          <p className="card-text priceTag flex-grow-0">
                            ${product.productPrice}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      {openModal && (
        <Modal open={openModal} onClose={() => setOpenModal(false)} center>
          <img
            src={modalImage}
            alt={product.productName}
            style={{ width: "100%", height: "auto" }}
          />
        </Modal>
      )}
      <Footer></Footer>
    </>
  );
};

export default ProductDetailPage;
