import React, { useEffect, useState } from "react";
import {Link, useNavigate} from "react-router-dom";
import "../../css/CategoryPage.css";
import axios from "axios";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { useAuth } from "../../context/auth";

const CategoryPage = () => {
  const [auth] = useAuth();
  const apiUrl = `${process.env.REACT_APP_API}`;
  const [orders, setOrders] = useState([]);
  const isLoggedIn = !!auth.token;
  const userId = auth.currentUser.id;
  const navigate = useNavigate();
  useEffect(() => {
    if (auth.token) getOrders();
  }, [auth.token]);

  const getOrders = async () => {
    try {
      const { data } = await axios.get(`${apiUrl}/orders/${userId}`);
      
      setOrders(data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Header></Header>

        {isLoggedIn ? (<>
            <div className="container mb-4">
                <h1
                    className="mt-5 d-flex justify-content-center"
                    style={{
                        color: "#002E6A",
                        marginTop: "15px",
                        
                        fontWeight: "450",
                    }}
                >
                    {" "}
                    My Orders{" "}
                </h1>
                <div>
                    {orders.length === 0 ? (
                        <h3 className="mt-5">You have no orders yet, start shopping ! </h3>
                    ) : (
                        orders.map((order) => (
                            <div key={order._id} className="card mb-3 android">
                                <div className="card-body">
                                    <div className = "review" >
                                    <div >
                                        <h5 className="card-text" style={{ color: "green" }}>
                                            Status: {order.status}{" "}
                                        </h5>
                                        <span>
                                        <Link className="text-decoration-none"to={"/reviews"}>
                                           <b>Give Review </b>
                                        </Link>
                                    </span>
                                        <div
                                            style={{
                                                fontSize: "14px",
                                                color: "dark gray",
                                                fontStyle: "italic",
                                                marginTop: "1px",
                                                marginBottom: "5px",
                                            }}
                                        >
                                            {order.status === "Order Received"
                                                ? "(We are prepairing to ship your order)"
                                                : ``}
                                        </div>
                                        
                                        <div>
                                            {order.status === "Shipped" ?
                                            <>
                                                <p className="card-text" style= {{ marginTop: "10px",fontWeight: "450" }} >
                                                    Tracking Number: <a href={`https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLc=3&text28777=&tLabels=${order.trackingNumber}`}>{order.trackingNumber}</a>
                                                </p>
                                            </>
                                                : null}
                                        </div>
                                    </div>

                                   

                                </div>
                                    <p className="card-text" style={{ fontWeight: "450" }}>
                                        Total: ${order.total}
                                    </p>
                                    <p className="card-text" style={{ fontWeight: "450", marginTop:"-15px"}}>
                                        Order Date: {order.createdAt.substring(0, 10)}
                                    </p>
                                    
                                    <h6 className="card-subtitle mb-2 text-muted">Products:</h6>
                                    <hr style={{ marginLeft: "29px" }} />
                                    <ol>
                                        {order.products.map((p) => (
                                            <li key={p._id}>
                                                <Link
                                                    to={`/products/${p.product._id}`}
                                                    className="text-decoration-none"
                                                >
                                                    <img
                                                        src={p.product.images.front}
                                                        alt={p.product.productName}
                                                        width="90"
                                                        height="90"
                                                        style={{
                                                            objectFit: "contain",
                                                            marginRight: "10px",
                                                            display: "block",
                                                        }}
                                                        loading= "lazy"
                                                    />
                                                </Link>
                                                <p style={{ fontWeight: "350" }}>
                                                    {" "}
                                                    {p.product.productName} | Size: {p.selectedSize} |
                                                    Qty: {p.quantity} | Price:{" "}
                                                    <h10 style={{ fontWeight: "900px" }}>
                                                        ${p.product.salePrice > 0 ? p.product.salePrice : p.product.productPrice}{" "}
                                                    </h10>
                                                </p>
                                                <hr />
                                            </li>
                                        ))}
                                    </ol>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </>):(<div className="continer mt-5">
            <div className="d-flex justify-content-center">
                <p>You need to be logged in to view contents</p><br/>
            </div>
            <div className="d-flex justify-content-center">
                <button className="btn add-to-cart" onClick={()=> navigate("/login")}>Click to login</button>
            </div>
        </div>)}
      <Footer></Footer>
        </>
  );
};

export default CategoryPage;
